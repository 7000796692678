import { useUnit } from 'effector-react';
import type { PropsWithChildren } from 'react';
import { memo } from 'react';

import { Navigate } from '@kuna-pay/ui/router';

import { $$smsAs2FAFeatureFlag } from '@kuna-pay/merchant/shared/feature-flags/features/dynamic';
import { $$optional2FAFeatureFlag } from '@kuna-pay/merchant/shared/feature-flags/features/static';
import { routes } from '@kuna-pay/merchant/shared/router';

import { $$session } from '../../model';

type MFAEnabledProps = PropsWithChildren<{}> & {};

/**
 * We assume that NonExpiredTokensGuard is used before this guard.
 *
 * TODO: Remove this guard when KUPAY_2698_STORY_OPTIONAL_2FA is deployed.
 */
const MFAEnabledGuard = memo(({ children }: MFAEnabledProps) => {
  const is2FAEnabled = useUnit($$session.$$jwt.$$user.$isOtpEnabled);
  const is2FAOptionalFeatureFlag = useUnit($$optional2FAFeatureFlag);
  const isSMS2FAFeatureFlag = useUnit($$smsAs2FAFeatureFlag);

  if (!is2FAEnabled && !is2FAOptionalFeatureFlag.enabled) {
    return (
      <Navigate
        to={
          isSMS2FAFeatureFlag.enabled
            ? routes.auth.mfa.choose
            : routes.auth.mfa.turnOn
        }
      />
    );
  }

  return <>{children}</>;
});

export { MFAEnabledGuard };
