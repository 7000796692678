import { SetupAuthenticatorMFAModel } from './setup-authenticator-mfa.model';
import { SetupSMSMFAModel } from './setup-sms-mfa.model';
import { SetupAuthenticatorMFAForm, SetupSMSMFAForm } from './ui';

const SetupAuthenticatorMFA = Object.assign(SetupAuthenticatorMFAForm, {
  factory: SetupAuthenticatorMFAModel,
  Form: SetupAuthenticatorMFAForm,
});

const SetupSMSMFA = Object.assign(SetupSMSMFAForm, {
  factory: SetupSMSMFAModel,
  Form: SetupSMSMFAForm,
});

export { SetupAuthenticatorMFA, SetupSMSMFA };
export type { SetupSMSMFAConfig } from './setup-sms-mfa.model';
export type { SetupAuthenticatorMFAFormProps } from './ui';
