import { SuccessOutput } from '@kuna-pay/merchant/generated/graphql';
import { subscription, query } from '@kuna-pay/merchant/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const resendOtpRequest = (
  select: Select<SuccessOutput> | string
) => `query resendOtp {
  data: resendOtp {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'SuccessOutput')}`;
export const resendOtp = (select: Select<SuccessOutput> | string) => () =>
  query<SuccessOutput>(resendOtpRequest(select));
