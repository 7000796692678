import clsx from 'clsx';
import { type HTMLAttributes, memo, type PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { urls } from '@kuna-pay/core/shared/config';

import styles from './link-docs-troubleshooting.module.scss';

type MFAHintProps = HTMLAttributes<HTMLDivElement> & {
  variant?: TypographyProps['variant'];

  linkVariant?: TypographyProps['variant'];
};

const MFAHint = memo(
  ({
    className,
    variant = 'body2',
    linkVariant = 'subtitle3',
    ...props
  }: MFAHintProps) => {
    const { t } = useTranslation('core');

    return (
      <div className={clsx(styles.mfaHintContainer, className)} {...props}>
        <Typography variant={variant} center>
          <Trans
            t={t}
            i18nKey='features.auth.mfa.verify.ui.hint'
            components={{
              'link-docs-troubleshouting': (
                <LinkDocsTrobleshooting variant={linkVariant} />
              ),
            }}
          />
        </Typography>
      </div>
    );
  }
);

type LinkDocsTrobleshootingProps = PropsWithChildren &
  Pick<TypographyProps, 'variant'>;

const LinkDocsTrobleshooting = memo(
  ({ variant, children }: LinkDocsTrobleshootingProps) => {
    const { i18n } = useTranslation('core');

    return (
      <a
        href={urls.docs.troubleShouting.withLocale(i18n.language)}
        target='_blank'
        rel='noreferrer'
      >
        <Typography className={styles.mfaHintLink} variant={variant}>
          {children}
        </Typography>
      </a>
    );
  }
);

export { LinkDocsTrobleshooting, MFAHint };
