import { Suspense } from 'react';

import { lazyWithRetryAndRefresh } from '@kuna-pay/utils/lazy';
import { Choose2FAMethodPageLoading } from '@kuna-pay/core/pages/auth/mfa/choose';

const Page = lazyWithRetryAndRefresh(
  'ChooseAuthenticatorForSetup2FAPage',
  () => import('./page.component')
);

const ChooseAuthenticatorForSetup2FAPage = {
  Component: () => (
    <Suspense fallback={<Choose2FAMethodPageLoading />}>
      <Page />
    </Suspense>
  ),
};

export { ChooseAuthenticatorForSetup2FAPage };
