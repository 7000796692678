import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Country } from 'react-phone-number-input';
import PhoneInputWithCountrySelect from 'react-phone-number-input';

import { TextField } from '@kuna-pay/ui/ui/text-field';
import type { FieldModel } from '@kuna-pay/form';
import { Field, useField } from '@kuna-pay/form';

import { CountrySelect } from './country-calling-code-select.component';
import styles from './phone-input-field.module.scss';

type PhoneInputFormFieldProps = {
  $$field: FieldModel<string>;
  countries: Country[];
  defaultCountry?: Country;
};

const PhoneInputFormField = memo(
  ({ $$field, defaultCountry, countries }: PhoneInputFormFieldProps) => {
    const { t } = useTranslation();

    const { value, onChange } = useField($$field);
    const {
      value: _,
      onChange: __,
      onFocus,
      onBlur,
      disabled,
      name,
      isError,
      helperText,
    } = Field.TextField.useFormTextField({
      field: $$field,
    });

    return (
      <TextField.Provider
        className={styles.phoneField}
        name={name}
        size='md-lg'
        label={t(
          'features.withdraw.ui.requisite-create.zen.connect-or-register.connection.form.phone.label'
        )}
        placeholder={t(
          'features.withdraw.ui.requisite-create.zen.connect-or-register.connection.form.phone.placeholder'
        )}
        variant='outlined'
        isError={isError}
        helperText={helperText}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <PhoneInputWithCountrySelect
          autoFocus
          value={value}
          onChange={(value) => onChange(value!)}
          disabled={disabled}
          international
          limitMaxLength
          countryCallingCodeEditable={false}
          defaultCountry={defaultCountry}
          countries={countries}
          inputComponent={TextField.Input}
          containerComponent={TextField.Container}
          countrySelectComponent={CountrySelect}
        />
      </TextField.Provider>
    );
  }
);

export { PhoneInputFormField };
