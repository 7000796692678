import { useUnit } from 'effector-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { DropdownProps } from '@kuna-pay/ui/ui/dropdown';
import { Dropdown } from '@kuna-pay/ui/ui/dropdown';
import { Skeleton } from '@kuna-pay/ui/ui/skeleton';
import { CrowdinInContextConfig } from '@kuna-pay/core/shared/i18n';

import { $$session } from '@kuna-pay/merchant/entities/session';
import { $$crowdinInContextFeatureFlag } from '@kuna-pay/merchant/shared/feature-flags/features/static';
import { $$i18n, I18nConfig } from '@kuna-pay/merchant/shared/i18n';

import { $$changeLanguage } from './change-language.model';
import styles from './change-language.module.scss';

type ChangeLanguageViewProps = Pick<DropdownProps, 'align' | 'variant'>;

const ChangeLanguageView = memo(
  ({ align = 'end', variant = 'numbers1' }: ChangeLanguageViewProps) => {
    const { i18n } = useTranslation();
    const [languages, jwtLanguage, changingLanguageTo, changeLanguage] =
      useUnit([
        $$i18n.$supportedLanguages,
        $$session.$$jwt.$$user.$language,
        $$changeLanguage.$$ui.$changingLanguageTo,
        $$changeLanguage.$$ui.changeLanguage,
      ]);
    const crowdinInContextFF = useUnit($$crowdinInContextFeatureFlag);

    // If we only have one language, we don't need to show the dropdown
    if (languages.length === 1) {
      return null;
    }

    if (!i18n.resolvedLanguage) {
      return <Skeleton height={24} width={70} />;
    }

    let visibleLanguage = changingLanguageTo ?? i18n.resolvedLanguage;

    if (crowdinInContextFF.enabled) {
      if (visibleLanguage === CrowdinInContextConfig.PSEUDO_LANGUAGE) {
        visibleLanguage = jwtLanguage ?? I18nConfig.FALLBACK_LANGUAGE;
      }
    }

    return (
      <Dropdown
        className={styles.dropdown}
        classes={{ triggerText: styles.dropdownText }}
        content={
          <div className={styles.list}>
            {languages.map((language) => (
              <Dropdown.Item
                className={styles.listButton}
                key={language}
                onClick={() => {
                  changeLanguage(language);
                }}
              >
                <Dropdown.ItemText variant={variant} className='text-black900'>
                  {language.toUpperCase()}
                </Dropdown.ItemText>
              </Dropdown.Item>
            ))}
          </div>
        }
        variant={variant}
      >
        <>{visibleLanguage.toUpperCase()}</>
      </Dropdown>
    );
  }
);

export { ChangeLanguageView };
