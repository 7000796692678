import type { Effect } from 'effector';

import { modelFactory } from '@kuna-pay/utils/effector';

import { SetupAuthenticatorMFA } from '../../../../features/auth/mfa/setup';

const TurnOn2FAAuthenticatorPageModel = modelFactory(
  (config: {
    generateSecretFx: Effect<void, { secret: string; uri: string }>;
    enableOtpFx: Effect<{ otp: string }, void>;
    refreshTokenAfterDoneFx: Effect<void, void>;
  }) => {
    const $$confirm = SetupAuthenticatorMFA.factory.createModel({
      generateSecretFx: config.generateSecretFx,
      enableOtpFx: config.enableOtpFx,
      refreshTokenAfterDoneFx: config.refreshTokenAfterDoneFx,
    });

    return {
      load: $$confirm.load,
      done: $$confirm.done,
      alreadyEnabled: $$confirm.alreadyEnabled,
      reset: $$confirm.reset,

      $$ui: {
        $$confirm,
      },
    };
  }
);

export { TurnOn2FAAuthenticatorPageModel };
