import clsx from 'clsx';
import { createEffect } from 'effector';
import { createContext, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { ModelOf } from '@kuna-pay/utils/effector';
import { getState } from '@kuna-pay/utils/effector';
import { invariant } from '@kuna-pay/utils/invariant';
import { useLocalModel } from '@kuna-pay/utils/react/use-local-model';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { TimeoutedResend } from '@kuna-pay/core/features/auth/abstract/timeouted-resend';

import type { VerifyOTPModel } from '../verify-2fa.model';
import styles from './resend.module.scss';

type ResendProps = TypographyProps & {
  $$model: ModelOf<typeof VerifyOTPModel>;
};

const ResendContext = createContext(async (_email: string) => {});

const Resend = memo(({ $$model, className, ...props }: ResendProps) => {
  const { t } = useTranslation();
  const resendOtp = useContext(ResendContext);

  const $$resend = useLocalModel(() =>
    TimeoutedResend.factory.createModel({
      resendFx: createEffect(async () => {
        try {
          const email = await getState($$model.$$ui.$email);
          invariant.error(email, 'Email is required to resend OTP');

          const resend = $$model.$$ui.resendVerifyCode ?? resendOtp;

          await resend(email);
        } catch (error) {
          console.error(error);
        }
      }),

      timeoutInSeconds: 60,
    })
  );

  return (
    <Typography
      as='p'
      className={clsx(styles.resend, className)}
      center
      {...props}
    >
      {t('features.auth.mfa.verify.ui.resend', { ns: 'core' })}{' '}
      <TimeoutedResend.Button $$model={$$resend} />
    </Typography>
  );
});

const ResendProvider = ResendContext.Provider;

export { Resend, ResendProvider };
