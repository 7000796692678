import { useUnit } from 'effector-react';
import { memo } from 'react';

import type { ModelOf } from '@kuna-pay/utils/effector';

import type { VerifyOTPModel } from './verify-2fa.model';
import { VerifyAuthenticator2FA } from './verify-authenticator-2fa.component';
import { VerifyEmail2FA } from './verify-email-2fa.component';
import { VerifySMS2FA } from './verify-sms-2fa.component';

type Verify2FAProps = {
  $$model: ModelOf<typeof VerifyOTPModel>;
};

const Verify2FA = memo(({ $$model }: Verify2FAProps) => {
  const [type] = useUnit([$$model.$$ui.$type]);

  switch (type) {
    case 'email': {
      return <VerifyEmail2FA $$model={$$model} />;
    }

    case 'authenticator': {
      return <VerifyAuthenticator2FA $$model={$$model} />;
    }

    case 'sms': {
      return <VerifySMS2FA $$model={$$model} />;
    }

    default: {
      return <VerifyAuthenticator2FA $$model={$$model} />;
    }
  }
});

export { Verify2FA };
