import clsx from 'clsx';
import { type HTMLAttributes, memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { ModelOf } from '@kuna-pay/utils/effector';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';
import type { AdaptivePaperProps } from '@kuna-pay/core/shared/ui/adaptive-paper';
import { AdaptivePaper } from '@kuna-pay/core/shared/ui/adaptive-paper';

import { IsTrustedDevice, MFAHint } from './ui';
import type { VerifyOTPModel } from './verify-2fa.model';
import { Verify2FAForm } from './verify-2fa-form.component';
import styles from './verify-authenticator-2fa.module.scss';

type VerifyAuthenticator2FAProps = {
  $$model: ModelOf<typeof VerifyOTPModel>;
};

const VerifyAuthenticator2FAView = memo(
  ({ $$model }: VerifyAuthenticator2FAProps) => (
    <C.Root>
      <C.Title variant='h9' />

      <C.Subtitle variant='body3' />

      <C.Form className={styles.form} $$model={$$model} />

      <C.Hint variant='body3' linkVariant='subtitle5' />
    </C.Root>
  )
);

const C = {
  Root: memo(({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <div className={clsx(styles.root, className)} {...props} />
  )),

  PaperRoot: memo(({ className, ...props }: AdaptivePaperProps) => (
    <AdaptivePaper
      className={clsx(styles.container, className)}
      scrollIntoView
      {...props}
    />
  )),

  Title: memo(({ className, ...props }: TypographyProps) => {
    const { t } = useTranslation();

    return (
      <Typography
        as='h1'
        className={clsx(styles.title, className)}
        center
        {...props}
      >
        {t('features.auth.mfa.verify.authenticator.title', { ns: 'core' })}
      </Typography>
    );
  }),

  Subtitle: memo(({ className, ...props }: TypographyProps) => {
    const { t } = useTranslation();

    return (
      <Typography
        as='h2'
        className={clsx(styles.subtitle, className)}
        center
        {...props}
      >
        {t('features.auth.mfa.verify.authenticator.subtitle', {
          ns: 'core',
        })}
      </Typography>
    );
  }),

  Form: Verify2FAForm,

  Hint: MFAHint,

  IsTrustedDevice,
};

const VerifyAuthenticator2FA = Object.assign(VerifyAuthenticator2FAView, C);

export { VerifyAuthenticator2FA };
