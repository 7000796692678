import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { modelView } from '@kuna-pay/utils/effector';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { State } from '@kuna-pay/core/shared/lib/state';
import { useTypedGate } from '@kuna-pay/core/shared/router';

import { SetupSMSMFAModel, SetupSMSState } from '../setup-sms-mfa.model';
import type { SendSMSCodeProps } from './send-sms-mfa-code.component';
import { SendSMSCode } from './send-sms-mfa-code.component';
import type { VerifySMSCodeProps } from './verify-sms-mfa-code.component';
import { VerifySMSCode } from './verify-sms-mfa-code.component';
import styles from './setup-sms-mfa-form.module.scss';

type SetupSMSMFAFormProps = {
  back?: ReactNode;

  props?: SendSMSCodeProps['props'] &
    VerifySMSCodeProps['props'] & {
      title?: Partial<TypographyProps>;
    };

  onWhatIsMfaClick?: () => void;
};

const SetupSMSMFAForm = modelView(
  SetupSMSMFAModel,
  ({ onWhatIsMfaClick, props, back }: SetupSMSMFAFormProps) => {
    const $$model = SetupSMSMFAModel.useModel();
    const i18n = useTranslation('core');

    useTypedGate({ Gate: $$model.Gate, props: { i18n } });

    return (
      <>
        <div className={styles.titleContainer}>
          {back}

          <Typography
            as='h1'
            className={styles.title}
            variant='h5'
            center
            {...(props?.title ?? {})}
          >
            {i18n.t('features.auth.mfa.setup.sms.title')}
          </Typography>
        </div>

        <State.Switch $$model={$$model.$$state}>
          <State.Case
            value={SetupSMSState.EnterPhone}
            view={
              <SendSMSCode props={props} onWhatIsMfaClick={onWhatIsMfaClick} />
            }
          />

          <State.Case
            value={SetupSMSState.VerifySMS}
            view={<VerifySMSCode props={props} />}
          />
        </State.Switch>
      </>
    );
  }
);

export { SetupSMSMFAForm };
export type { SetupSMSMFAFormProps };
