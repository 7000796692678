import { createDynamicFlag } from '../model/dynamic.factory';

const $$testDepositFeatureFlag = createDynamicFlag(
  'KUPAY_1738_MERCHANT_TEST_DEPOSIT'
);

const $$invoiceConvertFeatureFlag = createDynamicFlag(
  'KUPAY_2752_FE_MERCHANT_INVOICE_CONVERT'
);

const $$customInvoiceFieldFeatureFlag = createDynamicFlag(
  'KUPAY_2700_STORY_CUSTOM_FIELD_TO_QUESTIONNAIRE'
);

const $$tutorialsFeatureFlag = createDynamicFlag('KUPAY_2620_FE_TUTORIALS');

const $$notificationSettingsFeatureFlag = createDynamicFlag(
  'KUPAY_2678_STORY_NOTIFICATIONS_SETTINGS'
);

const $$notificationsFeatureFlag = createDynamicFlag(
  'KUPAY_2678_STORY_NOTIFICATIONS'
);

const $$paymentCoverageFeatureFlag = createDynamicFlag(
  'KUPAY_2858_FE_MERCHANT_PAYMENT_COVERAGE'
);

const $$smsAs2FAFeatureFlag = createDynamicFlag('KUPAY_2619_STORY_2FA_SMS');

const $$travelRuleFieldsForWithdrawFeatureFlag = createDynamicFlag(
  'KUPAY_2927_FE_MERCHANT_TRAVEL_RULE_FIELDS_FOR_WITHDRAW'
);

const $$massPayoutsFeatureFlag = createDynamicFlag('FE_MERCHANT_MASS_PAYOUTS');

const $$usdtForStatementReportFeatureFlag = createDynamicFlag(
  'KUPAY_2915_FE_MERCHANT_USDT_FOR_STATEMENT_REPORT'
);

export {
  $$customInvoiceFieldFeatureFlag,
  $$invoiceConvertFeatureFlag,
  $$massPayoutsFeatureFlag,
  $$notificationSettingsFeatureFlag,
  $$notificationsFeatureFlag,
  $$paymentCoverageFeatureFlag,
  $$smsAs2FAFeatureFlag,
  $$testDepositFeatureFlag,
  $$travelRuleFieldsForWithdrawFeatureFlag,
  $$tutorialsFeatureFlag,
  $$usdtForStatementReportFeatureFlag,
};
