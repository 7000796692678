import type { Effect } from 'effector';
import { attach, combine, createEvent, createStore } from 'effector';
import { createGate } from 'effector-react';
import i18next from 'i18next';
import { or } from 'patronum';
import type { useTranslation } from 'react-i18next';

import { listen, modelFactory, setState } from '@kuna-pay/utils/effector';
import { notify } from '@kuna-pay/ui/notification';

import { ErrorMatcher } from '../../../../shared/api';
import { VerifyOneTimeCode } from './@x';
import { VerifyGeneratedSecretErrors } from './setup-mfa.types';

type SetupAuthenticatorMFAModelConfig = {
  generateSecretFx: Effect<void, { secret: string; uri: string }>;
  enableOtpFx: Effect<{ otp: string }, void>;
  refreshTokenAfterDoneFx: Effect<void, void>;
};

const SetupAuthenticatorMFAModel = modelFactory(
  (config: SetupAuthenticatorMFAModelConfig) => {
    const Gate = createGate<{ i18n: ReturnType<typeof useTranslation> }>();

    const generateSecretFx = attach({ effect: config.generateSecretFx });
    const enableOtpFx = attach({ effect: config.enableOtpFx });

    const load = createEvent();
    const reset = createEvent();
    const alreadyEnabled = createEvent();
    const done = createEvent();

    const $uri = createStore('');
    const $secret = createStore('');

    const $$verifySecret = VerifyOneTimeCode.factory.createModel({
      verifyFx: enableOtpFx,

      $disabled: or(
        generateSecretFx.pending,
        enableOtpFx.pending,
        config.refreshTokenAfterDoneFx.pending
      ),

      errors: {
        incorrectOtp: {
          match: ErrorMatcher.fromEffectPayload(
            VerifyGeneratedSecretErrors.INCORRECT_OTP
          ),

          $message: combine(Gate.state, () =>
            i18next.t(
              'features.auth.mfa.setup.model.verify.errors.incorrect-otp',
              { ns: 'core' }
            )
          ),
        },

        alreadyUsed: {
          match: ErrorMatcher.fromEffectPayload(
            VerifyGeneratedSecretErrors.OTP_ALREADY_USED
          ),

          $message: combine(Gate.state, () =>
            i18next.t(
              'features.auth.mfa.setup.model.verify.errors.already-used',
              { ns: 'core' }
            )
          ),
        },
      },
    });

    listen({
      clock: load,
      handler: async () => {
        try {
          const { uri, secret } = await generateSecretFx();

          setState($uri, uri);
          setState($secret, secret);
        } catch (error) {
          if (ErrorMatcher.createErrorMatcher('OTP_ENABLED')(error)) {
            await config.refreshTokenAfterDoneFx().finally(() => {
              alreadyEnabled();
            });

            return;
          }

          if (!ErrorMatcher.isMarkedAsHandled(error)) {
            notify.warning(
              i18next.t(
                'features.auth.mfa.setup.model.generate-secret.failed.unknown',
                { ns: 'core' }
              )
            );
          }
        }
      },
    });

    listen({
      clock: enableOtpFx.fail,
      handler: () => {
        $$verifySecret.setFormError(
          i18next.t('features.auth.mfa.setup.model.enable-otp.failed.unknown', {
            ns: 'core',
          })
        );
      },
    });

    listen({
      clock: $$verifySecret.done,
      handler: async () => {
        await config.refreshTokenAfterDoneFx().finally(() => {
          done();
        });
      },
    });

    listen({
      clock: reset,
      handler: () => {
        $$verifySecret.reset();
        $uri.reinit!();
        $secret.reinit!();
      },
    });

    return {
      load,
      done,
      alreadyEnabled,
      reset,

      $$ui: {
        Gate,
        $pending: generateSecretFx.pending,

        $secret,
        $uri,

        $$verifySecret,
      },
    };
  }
);

export type { SetupAuthenticatorMFAModelConfig };
export { SetupAuthenticatorMFAModel };
