import { modelView } from '@kuna-pay/utils/effector';
import { AdaptivePaper } from '@kuna-pay/core/shared/ui/adaptive-paper';

import type { SetupAuthenticatorMFAFormProps } from '../../../../features/auth/mfa/setup';
import { SetupAuthenticatorMFA } from '../../../../features/auth/mfa/setup';
import { TurnOn2FAAuthenticatorPageModel } from './page.model';
import styles from './page.module.scss';

type TurnOn2FARRPageProps = Pick<SetupAuthenticatorMFAFormProps, 'back'> & {
  onWhatIsMfaClick?: () => void;
};

const TurnOn2FAAuthenticatorPage = modelView(
  TurnOn2FAAuthenticatorPageModel,
  ({ onWhatIsMfaClick, back }: TurnOn2FARRPageProps) => {
    const $$model = TurnOn2FAAuthenticatorPageModel.useModel();

    return (
      <AdaptivePaper className={styles.root}>
        <SetupAuthenticatorMFA.Form
          $$model={$$model.$$confirm}
          onWhatIsMfaClick={onWhatIsMfaClick}
          back={back}
        />
      </AdaptivePaper>
    );
  }
);

export { TurnOn2FAAuthenticatorPage };
