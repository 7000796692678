import { useUnit } from 'effector-react';
import type { PropsWithChildren } from 'react';
import { memo, useCallback } from 'react';

import { MFAInterceptor } from '@kuna-pay/core/features/auth/mfa/verify-2fa';

import { $$api } from '@kuna-pay/merchant/shared/api';
import { resendOtp } from '@kuna-pay/merchant/shared/api/generated/Otp/request/resendOtp';
import { GraphqlClient } from '@kuna-pay/merchant/shared/api/graphql';

import { $$session } from '../model';

const MerchantMfaInterceptor = Object.assign(
  memo(({ children }: PropsWithChildren) => {
    const [email, isOtpEnabled] = useUnit([
      $$session.$$jwt.$$user.$email,
      $$session.$$jwt.$$user.$isOtpEnabled,
    ]);

    const resendOtpFn = useCallback(async () => {
      await resendOtp({ success: true })();
    }, []);

    if (!email) {
      return children;
    }

    return (
      <MFAInterceptor
        email={email}
        isOtpEnabled={isOtpEnabled}
        resendOtp={resendOtpFn}
        $$api={$$api}
        $$client={GraphqlClient.get()}
      >
        {children}
      </MFAInterceptor>
    );
  }),
  {
    Gate: MFAInterceptor.Gate,
  }
);

export { MerchantMfaInterceptor };
