import { Suspense } from 'react';

import { lazyWithRetryAndRefresh } from '@kuna-pay/utils/lazy';
import { TurnOn2FASMSPageLoading } from '@kuna-pay/core/pages/auth/mfa/sms/page.loading';

const Page = lazyWithRetryAndRefresh(
  'TurnOn2FASMSPage',
  () => import('./page.component')
);

const TurnOn2FASMSPage = {
  Component: () => (
    <Suspense fallback={<TurnOn2FASMSPageLoading />}>
      <Page />
    </Suspense>
  ),
};

export { TurnOn2FASMSPage };
