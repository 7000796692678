import clsx from 'clsx';
import { useUnit } from 'effector-react';
import type { HTMLAttributes } from 'react';
import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { ModelOf } from '@kuna-pay/utils/effector';
import { Typography, type TypographyProps } from '@kuna-pay/ui/ui/typography';
import {
  AdaptivePaper,
  type AdaptivePaperProps,
} from '@kuna-pay/core/shared/ui/adaptive-paper';

import { Resend } from './ui';
import type { VerifyOTPModel } from './verify-2fa.model';
import { Verify2FAForm } from './verify-2fa-form.component';
import styles from './verify-email-2fa.module.scss';

type VerifyEmail2FAProps = {
  $$model: ModelOf<typeof VerifyOTPModel>;
};

const VerifyEmail2FAView = memo(({ $$model }: VerifyEmail2FAProps) => (
  <C.Root>
    <C.Img />

    <C.Title variant='h7' />

    <C.Subtitle $$model={$$model} variant='body3' />

    <C.Form className={styles.form} $$model={$$model} />

    <C.Resend variant='body2' $$model={$$model} />
  </C.Root>
));

const C = {
  Root: memo(({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <div className={clsx(styles.root, className)} {...props} />
  )),

  PaperRoot: memo(({ className, ...props }: AdaptivePaperProps) => (
    <AdaptivePaper
      className={clsx(styles.paperRoot, className)}
      scrollIntoView
      {...props}
    />
  )),

  Img: memo(({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <div className={clsx(styles.imgContainer, className)} {...props}>
      <img src='/images/confirm-email.png' alt='' width={244} height={244} />
    </div>
  )),

  Title: memo(({ className, ...props }: TypographyProps) => {
    const { t } = useTranslation();

    return (
      <Typography
        as='h1'
        className={clsx(styles.title, className)}
        center
        {...props}
      >
        {t('features.auth.mfa.verify.authenticator.title', { ns: 'core' })}
      </Typography>
    );
  }),

  Subtitle: memo(
    ({
      className,
      $$model,
      ...props
    }: TypographyProps & {
      $$model: ModelOf<typeof VerifyOTPModel>;
    }) => {
      const { t } = useTranslation();
      const [email] = useUnit([$$model.$$ui.$email]);

      return (
        <Typography
          as='h2'
          className={clsx(styles.subtitle, className)}
          center
          {...props}
        >
          <Trans
            t={t}
            i18nKey='features.auth.mfa.verify.email.subtitle'
            values={{ email }}
            ns='core'
            components={{ b: <b /> }}
          />
        </Typography>
      );
    }
  ),

  Form: Verify2FAForm,

  Resend,
};

const VerifyEmail2FA = Object.assign(VerifyEmail2FAView, C);

export { VerifyEmail2FA };
