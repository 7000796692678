import type { Event } from 'effector';
import { createEvent, createStore } from 'effector';
import { useEffect } from 'react';

import {
  getState,
  listen,
  modelFactory,
  setState,
} from '@kuna-pay/utils/effector';
import { invariant } from '@kuna-pay/utils/invariant';
import { createExternalDepsRef } from '@kuna-pay/utils/react/use-local-model';
import { createSingleModal } from '@kuna-pay/ui/ui/modal';
import { ErrorMatcher } from '@kuna-pay/core/shared/api';
import type {
  ApiOTPService,
  GraphqlClientInterceptorOnReject,
  GraphqlClientInterceptorOnResolve,
  GraphqlRequest,
} from '@kuna-pay/core/shared/api/http';

import { Verify2FA } from './verify-2fa';
import { Verify2FAErrors } from './verify-2fa.types';

type InterceptedRequestWithMeta = {
  isFinished: boolean;
  resolve: (value: any) => void;
  reject: (error: any) => void;
  request: GraphqlRequest<any>;
  errors: any;
};

type MFAInterceptorApiService = {
  otpService: ApiOTPService;
};

type MFAInterceptorApiClientService = {
  query: (query: string, variables: Record<string, unknown>) => Promise<any>;

  use: (
    onResolve: GraphqlClientInterceptorOnResolve,
    onReject: GraphqlClientInterceptorOnReject
  ) => string;

  removeInterceptor: (id: string) => void;
};

type MFAInterceptorModelConfig = {
  $$api: MFAInterceptorApiService;
  $$client: MFAInterceptorApiClientService;

  ref: {
    email: string;
    isOtpEnabled: boolean;
    resendOtp: () => Promise<void>;
  };
};

const MFAInterceptorModelFactory = modelFactory(
  (config: MFAInterceptorModelConfig) => {
    const $$ref = createExternalDepsRef(config.ref);

    const setCustomView = createEvent<boolean>();
    const open = createEvent<InterceptedRequestWithMeta>();

    const $lastRequest = createStore<InterceptedRequestWithMeta | null>(null);

    const $customView = createStore(false);
    const $$modal = createSingleModal();

    const $$otp = Verify2FA.factory.createModel({
      verifyFx: async ({ otp }) => {
        const lastRequest = await getState($lastRequest);

        invariant.error(lastRequest, 'lastRequest is not defined');

        try {
          config.$$api.otpService.setOtp(otp);

          const result = await config.$$client.query(
            lastRequest.request.query,
            lastRequest.request.variables
          );

          setState($lastRequest, null);

          config.$$api.otpService.clearOtp();
          lastRequest.resolve(result);

          $$modal.close();
        } catch (error) {
          config.$$api.otpService.clearOtp();

          if (
            ErrorMatcher.createErrorMatcher([
              Verify2FAErrors.INCORRECT_OTP,
              Verify2FAErrors.OTP_ALREADY_USED,
            ])(error)
          ) {
            // Verify2FA will handle these errors
            throw error;
          }

          lastRequest.reject(error);
        }
      },

      resendVerifyCode: async () => {
        try {
          console.log('resend.start');
          const { resendOtp } = await getState($$ref.$current);

          await resendOtp();
          console.log('resend.done');
        } catch (error) {
          console.error(error);
        }
      },
    });

    listen({
      clock: open,
      source: $$ref.$current,
      handler: async (lastRequest, { email }) => {
        setState($lastRequest, lastRequest);

        const fields = ErrorMatcher.getApiErrorFields(lastRequest.errors);
        setState($$otp.$email, email);

        switch (fields?.type) {
          case 'sms': {
            setState($$otp.$type, 'sms');
            setState($$otp.$phone, fields?.to ?? null);
            break;
          }

          case 'email': {
            setState($$otp.$type, 'email');
            break;
          }

          default: {
            setState($$otp.$type, 'authenticator');
          }
        }

        setState($$otp.$isOtpOpened, true);
      },
    });

    listen({
      clock: $$otp.$isOtpOpened.updates,
      handler: (isOtpOpened) => {
        if (isOtpOpened) {
          $$modal.open();
        } else {
          $$modal.close();
        }
      },
    });

    listen({
      clock: setCustomView,
      source: $lastRequest,
      handler: (isCustomView, lastRequest) => {
        setState($customView, isCustomView);

        if (!isCustomView) {
          $$otp.reset();

          if (!lastRequest) {
            return;
          }

          if (!lastRequest.isFinished) {
            lastRequest.reject(lastRequest.errors);
          }
        }
      },
    });

    listen({
      clock: $$modal.closed,
      source: $lastRequest,
      handler: (_, lastRequest) => {
        setState($$otp.$isOtpOpened, false);

        if (!lastRequest) {
          return;
        }

        if (!lastRequest.isFinished) {
          lastRequest.reject(lastRequest.errors);
        }
      },
    });

    return {
      open,
      $customView,
      $$modal,
      $$ref,
      $$otp,

      $$ui: {
        $$otp,
        setCustomView,
      },

      useInstallInterceptor: (
        $$local: { open: Event<InterceptedRequestWithMeta> },
        { $$client }: { $$client: MFAInterceptorModelConfig['$$client'] }
      ) => {
        useEffect(() => {
          const interceptorId = $$client.use(
            (args) => args,
            (request, errors) => {
              if (
                ErrorMatcher.createErrorMatcher(Verify2FAErrors.OTP_REQUIRED)(
                  errors
                )
              ) {
                const promise = new Promise((resolve, reject) => {
                  let isFinished = false;

                  ErrorMatcher.markAsHandled(errors);

                  $$local.open({
                    isFinished,
                    resolve: (args: any) => {
                      isFinished = true;
                      resolve(args);
                    },
                    reject: (error: any) => {
                      isFinished = true;
                      reject(error);
                    },
                    request,
                    errors,
                  });
                });

                return promise;
              }

              throw errors;
            }
          );

          return () => {
            $$client.removeInterceptor(interceptorId);
          };
        }, [$$client]);
      },
    };
  }
);

export { MFAInterceptorModelFactory };
export type {
  MFAInterceptorApiClientService,
  MFAInterceptorApiService,
  MFAInterceptorModelConfig,
};
