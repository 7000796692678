import clsx from 'clsx';
import { useUnit } from 'effector-react';
import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { TimeoutedResend } from '@kuna-pay/core/features/auth/abstract/timeouted-resend';
import { VerifyOneTimeCode } from '@kuna-pay/core/features/auth/abstract/verify-one-time-code';

import { SetupSMSMFAModel } from '../setup-sms-mfa.model';
import styles from './verify-sms-mfa-code.module.scss';

type VerifySMSCodeProps = {
  props?: {
    subtitle?: Partial<TypographyProps>;
  };
};

const VerifySMSCode = memo(({ props }: VerifySMSCodeProps) => {
  const $$model = SetupSMSMFAModel.useModel();
  const i18n = useTranslation('core');
  const [phone] = useUnit([$$model.$$form.fields.phone.$value]);

  return (
    <>
      <Typography
        as='h2'
        className={styles.subtitle}
        variant='subtitle5_1'
        center
        {...(props?.subtitle ?? {})}
      >
        <Trans
          t={i18n.t}
          i18nKey='features.auth.mfa.setup.sms.description-2'
          components={{ s4: <Typography variant='subtitle4' /> }}
          values={{ phone: formatPhoneNumberIntl(phone) }}
        />
      </Typography>

      <div className={styles.formContainer}>
        <VerifyOneTimeCode.Form
          name='setup-mfa-form'
          $$model={$$model.$$verifySecret}
        >
          <Typography
            as='p'
            className={clsx(styles.resend)}
            center
            variant='body3'
          >
            {i18n.t('features.auth.mfa.verify.ui.resend', { ns: 'core' })}{' '}
            <TimeoutedResend.Button
              $$model={$$model.$$resend}
              variant='subtitle5'
            />
          </Typography>
        </VerifyOneTimeCode.Form>
      </div>
    </>
  );
});

export type { VerifySMSCodeProps };
export { VerifySMSCode };
