import { Suspense } from 'react';

import { lazyWithRetryAndRefresh } from '@kuna-pay/utils/lazy';

import { TurnOn2FAAuthenticatorPageLoading } from './page.loading';

const Page = lazyWithRetryAndRefresh(
  'TurnOn2FAPage',
  () => import('./page.component')
);

const TurnOn2FAAuthenticatorPage = {
  Component: () => (
    <Suspense fallback={<TurnOn2FAAuthenticatorPageLoading />}>
      <Page />
    </Suspense>
  ),
};

export { TurnOn2FAAuthenticatorPage };
