import { combine, createEffect, createEvent, createStore } from 'effector';
import { createGate } from 'effector-react';
import i18next from 'i18next';
import { or } from 'patronum';
import type { useTranslation } from 'react-i18next';
import {
  type Country,
  isPossiblePhoneNumber,
  isSupportedCountry,
} from 'react-phone-number-input';
import countryNamesJson from 'react-phone-number-input/locale/en.json';
import type { SchemaOf } from 'yup';
import { object, string } from 'yup';

import {
  getState,
  listen,
  modelFactory,
  setState,
} from '@kuna-pay/utils/effector';
import { invariant } from '@kuna-pay/utils/invariant';
import { notify } from '@kuna-pay/ui/notification';
import { createForm } from '@kuna-pay/form';
import { ValidationVisibilityCondition } from '@kuna-pay/form/lib/types';
import { TimeoutedResend } from '@kuna-pay/core/features/auth/abstract/timeouted-resend';
import { VerifyOneTimeCode } from '@kuna-pay/core/features/auth/abstract/verify-one-time-code';
import { VerifyGeneratedSecretErrors } from '@kuna-pay/core/features/auth/mfa/setup/setup-mfa.types';
import type { MutationPreRequestEnableOtpArgs } from '@kuna-pay/core/generated/public/graphql';
import { OtpType } from '@kuna-pay/core/generated/public/graphql';
import { createEQuery } from '@kuna-pay/core/shared/lib/effector-query';
import { State } from '@kuna-pay/core/shared/lib/state';

import { ErrorMatcher } from '../../../../shared/api';

type FormInValues = {
  phone: string;
};

enum SetupSMSState {
  EnterPhone = 'EnterPhone',
  VerifySMS = 'VerifySMS',
}

type SetupSMSMFAConfig = {
  findSmsSupportedPhoneCountryCodes: (_: void) => Promise<{ code: string }[]>;
  preRequestEnableOtp: (
    args: MutationPreRequestEnableOtpArgs
  ) => Promise<{ success: boolean }>;
  enableOtpFx: (args: { otp: string }) => Promise<void>;
  refreshTokenAfterDoneFx: () => Promise<void>;
};

const SetupSMSMFAModel = modelFactory((config: SetupSMSMFAConfig) => {
  const Gate = createGate<{ i18n: ReturnType<typeof useTranslation> }>();

  const load = createEvent();
  const done = createEvent();
  const alreadyEnabled = createEvent();
  const reset = createEvent();

  const findSmsSupportedPhoneCountryCodesFx = createEffect(
    config.findSmsSupportedPhoneCountryCodes
  );
  const enableOtpFx = createEffect(config.enableOtpFx);
  const refreshTokenAfterDoneFx = createEffect(config.refreshTokenAfterDoneFx);
  const preRequestEnableOtpFx = createEffect(config.preRequestEnableOtp);

  const $$state = State.factory.createModel<SetupSMSState>(
    SetupSMSState.EnterPhone
  );

  const $defaultCountry = createStore<Country>('UA');

  const $$form = createForm<FormInValues>({
    initialValues: { phone: '' },
    showValidationWhen: [ValidationVisibilityCondition.Submitted],
    schema: combine(
      Gate.state,
      (): SchemaOf<FormInValues> =>
        object({
          phone: string()
            .required()
            .test('is-possible-valid-phone-number', (phone = '', ctx) => {
              if (!phone) return true;

              const isValid = isPossiblePhoneNumber(phone);

              if (!isValid) {
                return ctx.createError({
                  path: ctx.path,
                  type: 'is-possible-valid-phone-number',
                  message: i18next.t(
                    'features.auth.mfa.setup.sms.form.phone.errors.invalid-phone-number',
                    { ns: 'core' }
                  ),
                  params: { phone },
                });
              }

              return true;
            })
            .label(
              i18next.t('features.auth.mfa.setup.sms.form.phone.errors.label', {
                ns: 'core',
              })
            ),
        })
    ),
  });

  const $$countries = createEQuery({
    initialData: [],
    query: async (_: void) => {
      const codes = await findSmsSupportedPhoneCountryCodesFx();

      const validSortedCodes = codes
        .map(({ code }) => code)
        .filter((code): code is Country => isSupportedCountry(code))
        .sort((a, b) => {
          const aCountry = countryNamesJson[a];

          const bCountry = countryNamesJson[b];

          return aCountry.localeCompare(bCountry);
        });

      try {
        const maybeCountryCode = (new Intl.Locale(navigator.language).region ??
          'UA') as Country;

        if (validSortedCodes.includes(maybeCountryCode)) {
          setState($defaultCountry, maybeCountryCode);
        }
      } catch (error) {
        console.error(error);
      }

      return validSortedCodes;
    },
  });

  const $$resend = TimeoutedResend.factory.createModel({
    resendFx: createEffect(async () => {
      try {
        const phone = await getState($$form.fields.phone.$value);

        invariant.error(phone, 'Phone is required to resend OTP');

        await preRequestEnableOtpFx({ type: OtpType.Sms, phoneNumber: phone });
      } catch (error) {
        console.error(error);

        if (ErrorMatcher.createErrorMatcher('OTP_ENABLED')(error)) {
          await config.refreshTokenAfterDoneFx().finally(() => {
            alreadyEnabled();
          });

          return;
        }

        if (!ErrorMatcher.isMarkedAsHandled(error)) {
          notify.warning(
            i18next.t('features.auth.mfa.setup.sms.send-sms.failed.unknown', {
              ns: 'core',
            })
          );
        }
      }
    }),

    timeoutInSeconds: 60,
  });

  const $$verifySecret = VerifyOneTimeCode.factory.createModel({
    verifyFx: enableOtpFx,

    $disabled: or(enableOtpFx.pending, refreshTokenAfterDoneFx.pending),

    errors: {
      incorrectOtp: {
        match: ErrorMatcher.fromEffectPayload(
          VerifyGeneratedSecretErrors.INCORRECT_OTP
        ),

        $message: combine(Gate.state, () =>
          i18next.t(
            'features.auth.mfa.setup.model.verify.errors.incorrect-otp',
            { ns: 'core' }
          )
        ),
      },

      alreadyUsed: {
        match: ErrorMatcher.fromEffectPayload(
          VerifyGeneratedSecretErrors.OTP_ALREADY_USED
        ),

        $message: combine(Gate.state, () =>
          i18next.t(
            'features.auth.mfa.setup.model.verify.errors.already-used',
            { ns: 'core' }
          )
        ),
      },
    },
  });

  listen({
    clock: load,
    handler: async () => {
      try {
        await $$countries.startFx();
      } catch (error) {
        console.error(error);

        if (!ErrorMatcher.isMarkedAsHandled(error)) {
          notify.warning(
            i18next.t(
              'features.auth.mfa.setup.sms.load-countries.failed.unknown',
              { ns: 'core' }
            )
          );
        }
      }
    },
  });

  listen({
    clock: $$form.submitted,
    handler: async ({ phone }) => {
      try {
        await preRequestEnableOtpFx({ type: OtpType.Sms, phoneNumber: phone });

        $$state.next(SetupSMSState.VerifySMS);
      } catch (error) {
        console.error(error);

        if (ErrorMatcher.createErrorMatcher('OTP_ENABLED')(error)) {
          await refreshTokenAfterDoneFx().finally(() => {
            alreadyEnabled();
          });

          return;
        }

        if (!ErrorMatcher.isMarkedAsHandled(error)) {
          notify.warning(
            i18next.t('features.auth.mfa.setup.sms.send-sms.failed.unknown', {
              ns: 'core',
            })
          );
        }
      }
    },
  });

  listen({
    clock: $$verifySecret.done,
    handler: async () => {
      await refreshTokenAfterDoneFx().finally(() => {
        done();
      });
    },
  });

  listen({
    clock: Gate.close,
    handler: () => {
      reset();
    },
  });

  listen({
    clock: reset,
    handler: () => {
      // $defaultCountry.reinit!();
      // $$countries.reset();
      $$form.reinit();
      $$state.reset();
    },
  });

  return {
    load,
    done,
    alreadyEnabled,
    reset,

    $$ui: {
      Gate,

      $defaultCountry,
      $$state,
      $$form,
      $$countries,
      $$resend,
      $$verifySecret,
    },
  };
});

export type { SetupSMSMFAConfig };
export { SetupSMSMFAModel, SetupSMSState };
