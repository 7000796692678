import { useUnit } from 'effector-react';
import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ExternalLink } from '@kuna-pay/ui/router';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { Form } from '@kuna-pay/form';
import { urls } from '@kuna-pay/core/shared/config';
import { PhoneInputFormField } from '@kuna-pay/core/shared/ui/phone-input-field';

import { SetupSMSMFAModel } from '../setup-sms-mfa.model';
import styles from './send-sms-mfa-code.module.scss';

type SendSMSCodeProps = {
  props?: {
    subtitle?: Partial<TypographyProps>;
    whatIsMfa?: Partial<TypographyProps>;
  };

  onWhatIsMfaClick?: () => void;
};

const SendSMSCode = memo(({ props, onWhatIsMfaClick }: SendSMSCodeProps) => {
  const i18n = useTranslation('core');

  return (
    <>
      <Typography
        as='h2'
        className={styles.subtitle}
        variant='subtitle5_1'
        center
        {...(props?.subtitle ?? {})}
      >
        <Trans
          t={i18n.t}
          i18nKey='features.auth.mfa.setup.sms.description'
          components={{ s4: <Typography variant='subtitle4' /> }}
        />
      </Typography>

      <div className={styles.formContainer}>
        <SendSMSCodeForm />
      </div>

      <ExternalLink
        className={styles.whatIsMfa}
        href={urls.whatIs2FA}
        target='_blank'
        rel='noreferrer'
        onClick={onWhatIsMfaClick}
      >
        <Typography variant='subtitle5' center {...(props?.whatIsMfa ?? {})}>
          {i18n.t('pages.auth.mfa.turn-on.help')}
        </Typography>
      </ExternalLink>
    </>
  );
});

const SendSMSCodeForm = memo(() => {
  const $$model = SetupSMSMFAModel.useModel();
  const { t } = useTranslation('core');

  const [defaultCountry, countries] = useUnit([
    $$model.$defaultCountry,
    $$model.$$countries.$data,
  ]);

  return (
    <Form name='setup-sms-mfa-form' use={$$model.$$form}>
      <PhoneInputFormField
        $$field={$$model.$$form.fields.phone}
        defaultCountry={defaultCountry}
        countries={countries}
      />

      <Form.Submit
        className={styles.submit}
        variant='contained'
        color='primary'
        size='lg'
        fullWidth
      >
        {t('features.auth.abstract.verify-one-time-code.ui.action')}
      </Form.Submit>
    </Form>
  );
});

export { SendSMSCode };
export type { SendSMSCodeProps };
