import type { ComponentPropsWithoutRef } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from '@kuna-pay/ui/router';
import { BaseButton, Button } from '@kuna-pay/ui/ui/button';
import { Tag } from '@kuna-pay/ui/ui/tag';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { AdaptivePaper } from '@kuna-pay/core/shared/ui/adaptive-paper';

import { ReactComponent as ArrowRightDropdown24Icon } from './assets/arrow-right-dropdown.svg';
import { ReactComponent as AuthenticatorIcon } from './assets/authenticator.svg';
import { ReactComponent as SMSIcon } from './assets/sms.svg';
import styles from './page.module.scss';

type Choose2FAMethodPageProps = {
  googleAuthenticator: ComponentPropsWithoutRef<typeof Link>;
  sms: ComponentPropsWithoutRef<typeof Link>;
  skip: ComponentPropsWithoutRef<typeof Link>;
};

const Choose2FAMethodPage = memo(
  ({ googleAuthenticator, sms, skip }: Choose2FAMethodPageProps) => {
    const { t } = useTranslation();

    return (
      <AdaptivePaper className={styles.root}>
        <Typography as='h1' className={styles.title} variant='h5' center>
          {t('pages.auth.mfa.choose.title', { ns: 'core' })}
        </Typography>

        <Typography as='h4' className={styles.subtitle} variant='body3' center>
          {t('pages.auth.mfa.choose.description', { ns: 'core' })}
        </Typography>

        <div className={styles.methods}>
          <BaseButton className={styles.button} fullWidth asChild>
            <Link {...googleAuthenticator}>
              <AuthenticatorIcon />

              <div className={styles.buttonContent}>
                <Button.Text size='lg'>
                  {t('pages.auth.mfa.choose.google-authenticator.title', {
                    ns: 'core',
                  })}
                </Button.Text>

                <Tag classes={{ root: styles.badge }} variant='success'>
                  {t('pages.auth.mfa.choose.google-authenticator.badge', {
                    ns: 'core',
                  })}
                </Tag>
              </div>

              <ArrowRightDropdown24Icon />
            </Link>
          </BaseButton>

          <BaseButton className={styles.button} fullWidth asChild>
            <Link {...sms}>
              <SMSIcon />

              <div className={styles.buttonContent}>
                <Button.Text size='lg'>
                  {t('pages.auth.mfa.choose.sms.title', { ns: 'core' })}
                </Button.Text>
              </div>

              <ArrowRightDropdown24Icon />
            </Link>
          </BaseButton>
        </div>

        <Button.Root variant='text' color='primary' size='xl' fullWidth asChild>
          <Link {...skip}>
            <Button.Text size='xl'>
              {t('pages.auth.mfa.choose.skip', { ns: 'core' })}
            </Button.Text>
          </Link>
        </Button.Root>
      </AdaptivePaper>
    );
  }
);

Choose2FAMethodPage.displayName = 'Choose2FAMethodPage';

export { Choose2FAMethodPage };
