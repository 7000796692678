import clsx from 'clsx';
import { useUnit } from 'effector-react';
import { type HTMLAttributes, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { ModelOf } from '@kuna-pay/utils/effector';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';
import type { AdaptivePaperProps } from '@kuna-pay/core/shared/ui/adaptive-paper';
import { AdaptivePaper } from '@kuna-pay/core/shared/ui/adaptive-paper';

import { IsTrustedDevice, MFAHint, Resend } from './ui';
import type { VerifyOTPModel } from './verify-2fa.model';
import { Verify2FAForm } from './verify-2fa-form.component';
import styles from './verify-sms-2fa.module.scss';

type VerifySMS2FAProps = {
  $$model: ModelOf<typeof VerifyOTPModel>;
};

const VerifySMS2FAView = memo(({ $$model }: VerifySMS2FAProps) => (
  <C.Root>
    <C.Title variant='h9' />

    <C.Subtitle variant='body3' $$model={$$model} />

    <C.Form className={styles.form} $$model={$$model}>
      <C.Resend className={styles.resend} variant='body3' $$model={$$model} />
    </C.Form>

    <C.Hint variant='body3' linkVariant='subtitle5' />
  </C.Root>
));

const C = {
  Root: memo(({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <div className={clsx(styles.root, className)} {...props} />
  )),

  PaperRoot: memo(({ className, ...props }: AdaptivePaperProps) => (
    <AdaptivePaper
      className={clsx(styles.container, className)}
      scrollIntoView
      {...props}
    />
  )),

  Title: memo(({ className, ...props }: TypographyProps) => {
    const { t } = useTranslation();

    return (
      <Typography
        as='h1'
        className={clsx(styles.title, className)}
        center
        {...props}
      >
        {t('features.auth.mfa.verify.sms.title', { ns: 'core' })}
      </Typography>
    );
  }),

  Subtitle: memo(
    ({
      className,
      $$model,
      ...props
    }: TypographyProps & {
      $$model: ModelOf<typeof VerifyOTPModel>;
    }) => {
      const { t } = useTranslation();
      const [phone] = useUnit([$$model.$phone]);

      return (
        <Typography
          as='h2'
          className={clsx(styles.subtitle, className)}
          center
          {...props}
        >
          <Trans
            t={t}
            i18nKey='features.auth.mfa.verify.sms.subtitle'
            ns='core'
            values={{ phone }}
            components={{ b: <Typography variant='subtitle4' /> }}
          />
        </Typography>
      );
    }
  ),

  Form: Verify2FAForm,

  Hint: MFAHint,

  Resend,

  IsTrustedDevice,
};

const VerifySMS2FA = Object.assign(VerifySMS2FAView, C);

export { VerifySMS2FA };
