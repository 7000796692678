/* eslint-disable react/no-unused-prop-types */
import clsx from 'clsx';
import { combine, createEvent, createStore } from 'effector';
import type { ReactNode } from 'react';
import { memo, useMemo } from 'react';
import type { Country } from 'react-phone-number-input';
import { getCountryCallingCode } from 'react-phone-number-input';
import countryNamesJson from 'react-phone-number-input/locale/en.json';

import { listen, setState } from '@kuna-pay/utils/effector';
import {
  createExternalDepsRef,
  useLocalModel,
  useSyncExternalDeps,
} from '@kuna-pay/utils/react/use-local-model';
import { SingleSelect } from '@kuna-pay/ui/components/select';

import styles from './country-calling-code-select.module.scss';

type ReactPhoneInputNumberCountrySelectProps = {
  'aria-label': string;
  disabled?: boolean;
  iconComponent?: ReactNode;
  name?: string | undefined;
  onBlur: (event: any) => void;
  onChange: (newCountry: any) => void;
  onFocus: (event: any) => void;
  options: { label: string; value?: string }[];
  readOnly?: boolean | undefined;
  value: string | undefined;
};

function getCountryENLabel(countryCode: string): string {
  return (countryNamesJson as Record<string, string>)[countryCode];
}

const CountrySelect = memo((props: ReactPhoneInputNumberCountrySelectProps) => {
  const options = useMemo(
    () =>
      props.options.filter(
        (option): option is { label: string; value: string } => !!option.value
      ),
    [props.options]
  );

  const { $$ref, $$select } = useLocalModel(() => {
    const $$ref = createExternalDepsRef({
      options,
      value: props.value,
      onChange: props.onChange,
    });

    const $options = createStore(options.map((option) => option.value));

    const $refValue = combine($$ref.$current, (props) => props.value ?? null);
    const $refOptions = combine($$ref.$current, (props) => props.options);

    const onChange = createEvent<string | null>();

    const $$select = SingleSelect.factory.createModel({
      $$loader: SingleSelect.factory.Loader.createSyncLoader({
        options: $options,

        filter: (countryCodes, query) => {
          let lowerCaseQuery = query.toLowerCase();

          if (lowerCaseQuery.startsWith('+')) {
            lowerCaseQuery = lowerCaseQuery.replace('+', '');
          }

          return countryCodes.filter((countryCode) => {
            if (countryCode.includes(lowerCaseQuery)) return true;

            const countryName = getCountryENLabel(countryCode);

            if (countryName.toLowerCase().includes(lowerCaseQuery)) return true;

            const callingCode = getCountryCallingCode(countryCode as Country);

            return callingCode.includes(lowerCaseQuery);
          });
        },
      }),
      $$value: {
        $value: $refValue,
        change: onChange,
      },
    });

    listen({
      clock: $refOptions.updates,
      handler: (options) => {
        setState(
          $options,
          options.map((option) => option.value)
        );
      },
    });

    listen({
      clock: onChange,
      source: $$ref.$current,
      handler: (value, props) => {
        props.onChange(value);
      },
    });

    return { $$select, $$ref };
  });

  useSyncExternalDeps($$ref, {
    value: props.value,
    options,
    onChange: props.onChange,
  });

  return (
    <SingleSelect $$model={$$select}>
      <SingleSelect.Control.Trigger.Root
        className={clsx(styles.root, styles.outlined)}
        size='lg'
        variant='outlined'
        color='grey'
      >
        <SingleSelect.Control.Trigger.Content
          className={clsx(
            styles.triggerContent,
            styles.triggerContentLg,
            styles.triggerContentStart
          )}
        >
          <SingleSelect.Value.State.Filled>
            {(code) => <CountryFlagIcon code={code} />}
          </SingleSelect.Value.State.Filled>
        </SingleSelect.Control.Trigger.Content>

        <SingleSelect.Control.Trigger.Icon
          adornmentClassName={clsx(
            styles.dropdownIcon,
            styles.dropdownIconLg,
            styles.dropdownIconEnd
          )}
        />
      </SingleSelect.Control.Trigger.Root>

      <SingleSelect.Content className={styles.content} align='start'>
        <SingleSelect.Search
          placeholder='Search'
          size='md'
          variant='contained'
          color='grey'
        />

        <SingleSelect.Options.State.Empty>
          <SingleSelect.Content.Empty />
        </SingleSelect.Options.State.Empty>

        <SingleSelect.Options.State.Options>
          <SingleSelect.Options.List
            renderItem={(option) => (
              <SingleSelect.Options.Option.Checkbox.Root
                option={option}
                scrollToSelectedItemWhenOpen
              >
                {({ option: code }) => (
                  <div className={styles.option}>
                    <div className={styles.optionCountry}>
                      <CountryFlagIcon code={code} />

                      <SingleSelect.Options.Option.Checkbox.Text variant='subtitle5'>
                        {getCountryENLabel(code)}
                      </SingleSelect.Options.Option.Checkbox.Text>
                    </div>

                    <SingleSelect.Options.Option.Checkbox.Text variant='subtitle5'>
                      +{getCountryCallingCode(code as Country)}
                    </SingleSelect.Options.Option.Checkbox.Text>
                  </div>
                )}
              </SingleSelect.Options.Option.Checkbox.Root>
            )}
          />
        </SingleSelect.Options.State.Options>

        <SingleSelect.Options.State.Loading>
          <SingleSelect.Content.Loading />
        </SingleSelect.Options.State.Loading>

        <SingleSelect.Options.State.NotFound>
          <SingleSelect.Content.NotFound />
        </SingleSelect.Options.State.NotFound>
      </SingleSelect.Content>
    </SingleSelect>
  );
});

const CountryFlagIcon = memo(({ code }: { code: string }) => (
  <div className={styles.countryFlagIconContainer}>
    <img
      className={styles.countryFlagIcon}
      src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${code}.svg`}
      alt={code}
    />
  </div>
));

export { CountrySelect };
