import type { Store } from 'effector';
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  restore,
} from 'effector';
import { createGate } from 'effector-react';
import i18next from 'i18next';
import type { useTranslation } from 'react-i18next';

import { getState, listen, modelFactory } from '@kuna-pay/utils/effector';
import { VerifyOneTimeCode } from '@kuna-pay/core/features/auth/abstract/verify-one-time-code';
import { ErrorMatcher } from '@kuna-pay/core/shared/api';

import type { VerifyOtpPayload } from './verify-2fa.types';
import { Verify2FAErrors } from './verify-2fa.types';

type OTPFormType = 'email' | 'authenticator' | 'sms';

type VerifyOTPModelConfig = {
  verifyFx: (
    payload: VerifyOtpPayload,
    context: { isTrustedDevice: boolean }
  ) => Promise<void>;

  resendVerifyCode: (email: string) => Promise<void>;

  $disabled?: Store<boolean>;
};

const VerifyOTPModel = modelFactory((config: VerifyOTPModelConfig) => {
  const Gate = createGate<{ i18n: ReturnType<typeof useTranslation> }>();

  const onTrustedDeviceChange = createEvent<boolean>();
  const reset = createEvent();

  const done = createEvent<OTPFormType>();

  const $isOtpOpened = createStore(false);
  const $type = createStore<OTPFormType>('authenticator');
  const $email = createStore<string | null>(null);
  const $phone = createStore<string | null>(null);

  const $trustedDevice = restore(onTrustedDeviceChange, false);

  const $$form = VerifyOneTimeCode.factory.createModel({
    verifyFx: createEffect(async (payload: VerifyOtpPayload) => {
      const isTrustedDevice = await getState($trustedDevice);

      return config.verifyFx(payload, { isTrustedDevice });
    }),

    errors: {
      incorrectOtp: {
        match: ErrorMatcher.fromEffectPayload(Verify2FAErrors.INCORRECT_OTP),
        $message: combine(Gate.state, () =>
          i18next.t('features.auth.mfa.verify.errors.incorrect-otp', {
            ns: 'core',
          })
        ),
      },
      alreadyUsed: {
        match: ErrorMatcher.fromEffectPayload(Verify2FAErrors.OTP_ALREADY_USED),
        $message: combine(Gate.state, () =>
          i18next.t('features.auth.mfa.verify.errors.already-used', {
            ns: 'core',
          })
        ),
      },
    },
  });

  listen({
    clock: $$form.done,
    handler: async () => {
      const type = await getState($type);
      done(type);
    },
  });

  listen({
    clock: reset,
    handler: () => {
      $$form.reset();

      $isOtpOpened.reinit!();
      $type.reinit!();
      $email.reinit!();
      $phone.reinit!();
      $trustedDevice.reinit!();
    },
  });

  return {
    done,
    reset,
    setFormError: $$form.setFormError,

    $isOtpOpened,
    $type,
    $email,
    $phone,

    $$ui: {
      Gate,
      $$form,
      $email,
      $type,
      onTrustedDeviceChange,
      $trustedDevice,
      resendVerifyCode: config.resendVerifyCode,
    },
  };
});

export { VerifyOTPModel };
