import clsx from 'clsx';
import { useUnit } from 'effector-react';
import type { PropsWithChildren, ReactNode } from 'react';
import { memo, useEffect } from 'react';

import {
  useLocalModel,
  useSyncExternalDeps,
} from '@kuna-pay/utils/react/use-local-model';
import { Modal } from '@kuna-pay/ui/ui/modal';

import type { MFAInterceptorModelConfig } from './2fa-interceptor.model';
import { MFAInterceptorModelFactory } from './2fa-interceptor.model';
import { Verify2FA } from './verify-2fa';
import styles from './2fa-interceptor.module.scss';

type MFAInterceptorProps = PropsWithChildren &
  Pick<MFAInterceptorModelConfig, '$$api' | '$$client'> &
  MFAInterceptorModelConfig['ref'];

const MFAInterceptor = memo(
  ({ children, $$client, $$api, ...ref }: MFAInterceptorProps) => {
    const $$local = useLocalModel(() =>
      MFAInterceptorModelFactory.createModel({
        $$api,
        $$client,
        ref,
      })
    );

    useSyncExternalDeps($$local.$$ref, ref);
    $$local.useInstallInterceptor($$local, { $$client });

    const [isCustomView] = useUnit([$$local.$customView]);

    return (
      <MFAInterceptorModelFactory.Provider value={$$local.$$ui}>
        {children}

        {!isCustomView && (
          <Modal
            id={$$local.$$modal.ID}
            model={$$local.$$modal.$$modal}
            content={
              <Modal.Content
                className={clsx(styles.content, styles.contentMd)}
                size='md'
                rounded='lg'
              >
                <Modal.Close />

                <Verify2FA $$model={$$local.$$otp} />
              </Modal.Content>
            }
          >
            <div style={{ position: 'fixed' }} />
          </Modal>
        )}
      </MFAInterceptorModelFactory.Provider>
    );
  }
);

const MFAInterceptorCustomViewPlacementGate = memo(
  ({
    children,
    append,
    prepend,
  }: PropsWithChildren & {
    prepend?: ReactNode;
    append?: ReactNode;
  }) => {
    const $$model = MFAInterceptorModelFactory.useModel();

    const [isOtpOpened, onSetCustomViewPlacementForOtp] = useUnit([
      $$model.$$otp.$isOtpOpened,
      $$model.setCustomView,
    ]);

    useEffect(() => {
      onSetCustomViewPlacementForOtp(true);

      return () => {
        onSetCustomViewPlacementForOtp(false);
      };
    }, []);

    if (!isOtpOpened) {
      return children;
    }

    return (
      <>
        {prepend}
        <Verify2FA $$model={$$model.$$otp} />
        {append}
      </>
    );
  }
);

export { MFAInterceptor, MFAInterceptorCustomViewPlacementGate };
