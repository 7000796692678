import { useUnit } from 'effector-react';
import { memo, useId } from 'react';
import { useTranslation } from 'react-i18next';

import type { ModelOf } from '@kuna-pay/utils/effector';
import { Checkbox } from '@kuna-pay/ui/ui/checkbox';
import { Typography } from '@kuna-pay/ui/ui/typography';

import type { VerifyOTPModel } from '../verify-2fa.model';
import styles from './is-trusted-device.module.scss';

type IsTrustedDeviceProps = {
  $$model: ModelOf<typeof VerifyOTPModel>;
};

const IsTrustedDevice = memo(({ $$model }: IsTrustedDeviceProps) => {
  const { t } = useTranslation();
  const id = useId();

  const [isTrustedDevice, onTrustedDeviceChange] = useUnit([
    $$model.$$ui.$trustedDevice,
    $$model.$$ui.onTrustedDeviceChange,
  ]);

  return (
    <div className={styles.trustedDevice}>
      <Checkbox
        size='lg'
        id={id}
        checked={isTrustedDevice}
        onCheckedChange={(checked) => {
          onTrustedDeviceChange(!!checked);
        }}
      />

      <Typography as='label' htmlFor={id} variant='subtitle5'>
        {t('features.auth.mfa.verify.ui.trusted-device', {
          ns: 'core',
        })}
      </Typography>
    </div>
  );
});

export { IsTrustedDevice };
